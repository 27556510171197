var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-body"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-inside"},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"#3D414A","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.texts.tabsItems),function(item){return _c('v-tab',{key:item,staticClass:"global-tab"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-tabs-slider')],2),_c('v-tabs-items',{staticClass:" global-item-tab mt-5 ",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.tableDataInProcces.length == 0)?_c('empty-data',{attrs:{"screenHeight":_vm.screenHeight}}):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":true,"disable-sort":true,"headers":_vm.texts.table.headersInProcess,"items":_vm.tableDataInProcces},scopedSlots:_vm._u([{key:"item.dTotalPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$ "+_vm._s(item.dTotalPrice)+" USD ")])]}},{key:"item.sCurrentStatusName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.oStatus.nCount + ' / ' + item.oStatus.nTotal))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#000000"},on:{"click":function($event){return _vm.detailItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-pagination',{staticClass:"mt-2  mon",attrs:{"color":"yellow darken-1","length":_vm.iNumPages},model:{value:(_vm.iCurrentPage),callback:function ($$v) {_vm.iCurrentPage=$$v},expression:"iCurrentPage"}})]},proxy:true}])})],1)],1),_c('v-tab-item',[(_vm.tableDataInProcces.length == 0)?_c('empty-data',{attrs:{"screenHeight":_vm.screenHeight}}):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":true,"disable-sort":true,"headers":_vm.texts.table.headersInterrupted,"items":_vm.tableDataInProcces},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#000000"},on:{"click":function($event){return _vm.detailItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-pagination',{staticClass:"mt-2  mon",attrs:{"color":"yellow darken-1","length":_vm.iNumPages},model:{value:(_vm.iCurrentPage),callback:function ($$v) {_vm.iCurrentPage=$$v},expression:"iCurrentPage"}})]},proxy:true}])})],1)],1),_c('v-tab-item',[(_vm.tableDataInProcces.length == 0)?_c('empty-data',{attrs:{"screenHeight":_vm.screenHeight}}):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":true,"disable-sort":true,"headers":_vm.texts.table.headersFinaly,"items":_vm.tableDataInProcces},scopedSlots:_vm._u([{key:"item.dTotalPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$ "+_vm._s(item.dTotalPrice)+" USD ")])]}},{key:"item.iBillDays",fn:function(ref){
var item = ref.item;
return [( item.sCurrentStatusId == '38b51cdd-95ca-4870-acde-68ed35597adb')?_c('span',{staticClass:"f-s14 mon"},[_vm._v(" \""+_vm._s(_vm.texts.table.status.doesNotApply)+"\" ")]):_c('v-chip',[(item.oPaymentData.sPaymentMethodId === '3d0c5ce8-2eaf-479a-a49a-8d2c4f48f387')?_c('div',[_vm._v(" "+_vm._s(item.iBillDays)+" "),(item.iBillDays > 1)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.texts.table.status.days))]):_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.texts.table.status.day))])]):_c('div',[_vm._v(" Contado ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#000000"},on:{"click":function($event){return _vm.detailItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-pagination',{staticClass:"mt-2  mon",attrs:{"color":"yellow darken-1","length":_vm.iNumPages},model:{value:(_vm.iCurrentPage),callback:function ($$v) {_vm.iCurrentPage=$$v},expression:"iCurrentPage"}})]},proxy:true}])})],1)],1)],1)],1)]),_c('PoweredBy',{staticClass:" pa-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }